@import "./scss/variables.scss";
@import "./scss/fonts.scss";
@import "./scss/breakpoints.scss";

body {
  overflow: hidden;
  font-family: "pitch", pitch, Founders-Grotesk-Regular,
    "Founders-Grotesk-Regular";
  background-color: $black;
  @include media(">phone") {
    font-size: 12px;
  }
  @include media(">=tablet") {
    font-size: 22px;
  }
  @include media(">desktop") {
    font-size: 42px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

header {
  height: 100vh;
  color: $white;
  text-transform: uppercase;
}
