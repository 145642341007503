@import "./scss/variables.scss";
@import "./scss/breakpoints.scss";

.container {
  background-color: $black;
  overflow-y: hidden !important;
}
.rotateContainer {
  color: white;
  z-index: 5;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  span {
    transform: rotate(140deg);
    width: fit-content;
    height: 4rem;
    font-size: 42px;
    margin-bottom: 1rem;
  }
}

//  header and first scene
.intro,
.subIntro {
  position: absolute;
  opacity: 0;
}

.subIntro {
  width: 80%;
  display: flex;
  flex-wrap: nowrap;
}

.glitchContainer {
  height: 100%;
  width: 100%;
  opacity: 1;
}

.circle {
  height: 3.5px;
  width: 3.5px;
  position: absolute;
  border-radius: 100%;
  background-color: $white;
  opacity: 0;
}

// cloud
.cloudContainer {
  position: fixed;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  top: 0;
  opacity: 0;
}
.overlay {
  position: absolute;
  opacity: 0.6;
  height: 100%;
  width: 100%;
  background: black;
  z-index: 1;
}
.overlay1 {
  visibility: visible;
}
.overlay2 {
  visibility: hidden;
  opacity: 1;
  background: transparent;
  img {
    opacity: 0.5;
  }
}

.pin {
  height: 100vh;
  width: 100%;
  // border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: white;
  flex-direction: column;
}

.cloud {
  width: 100%;
  height: 100%;
  opacity: 1;
}
.cloudImg {
  scale: 2, 2;
  transform-origin: 60% 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-image: linear-gradient(
    rgba(111, 109, 109, 0.8),
    rgba(82, 82, 82, 0.8)
  );
  z-index: 1;
  position: absolute;
  z-index: 1;
}
.cloudShiftContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 0;
}
.onlyClouds {
  width: 100%;
  height: 100%;
  // background-color: white;
  position: absolute;
  // z-index: 0;
  opacity: 0;
  transform: scale(1.1, 1.1);
}
.onlyCloudsGrad {
  position: absolute;
  height: 100%;
  width: 100%;
}

.gradContainer {
  position: absolute;
  // position: relative;
  height: 100%;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}

//
.increasedCloudsContainer {
  position: absolute;
  opacity: 0;
  height: 100%;
  width: 100%;
}
.increasedClouds {
  width: 100%;
  height: 100%;
  // background-color: white;
  position: absolute;
  top: 0;
  z-index: 0;
  opacity: 0.9;
  transform: scale(1.09, 1.09);
}
.increasedCloudsGrad {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 1;
}

.speckCloudsContainer {
  position: absolute;
  visibility: hidden;
  height: 100%;
  width: 100%;
}

.speckCloudsGrad {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 1;
}

.ideasToLifeContainer {
  position: absolute;
  visibility: hidden;
  height: 100%;
  width: 100%;
}
.ideasToLifeGrad {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 1;
}

.viewer {
  height: 100%;
  margin-left: auto;
  margin-right: auto;

  width: 100%;
  position: relative;
  background-image: url("./img/Fireworks-x2.png");
  max-width: 90px;
  @include media(">=desktop") {
    background-image: url("./img/Fireworks-x3.png");
    max-width: 200px;
  }

  background-repeat: no-repeat;
  background-position: 0 50%;
}

.section {
  height: 50%;
}

.scene {
  height: 100%;
  width: 9em;
  @include media(">=desktop") {
    width: 5em;
  }
  position: absolute;
  left: 0;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.logo {
  width: 8em;
  visibility: hidden;
  margin-top: 8em;
}

.lastPin {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
