@import "../../scss/breakpoints.scss";
.textContainer {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  overflow-y: hidden;
}

.text {
  color: black;
  background-color: white;
  width: auto;
  height: fit-content;
  visibility: hidden;
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 800;
  text-transform: uppercase;

  &.lastText {
    margin-bottom: 3em;
    font-size: 22px;
    @include media(">phone", "<=tablet") {
      font-size: 24px;
    }
    @include media(">=desktop") {
      font-size: 56px;
    }
  }

  div {
    padding: 1rem 4rem;
  }
}
