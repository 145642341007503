.scrollContainer {
}

.scrollContainer {
  display: flex;
  position: fixed;
  z-index: 1;
  bottom: 120px;
  right: 25px;
  flex-direction: column;
  height: 40px;
  width: 60px;
  font-size: 0.7rem;
  align-items: center;
  justify-content: center;
  opacity: 0;

  .spacer {
    height: 20px;
    width: 100%;
    color: white;
    text-align: center;
    margin: 6px 0px 10px 0;
    font-size: 1rem;
    letter-spacing: 1px;
  }

  .up {
    transform: rotate(180deg);
  }

  .up,
  .down {
    height: 100%;
    cursor: pointer;
    pointer-events: none;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 100%;
    }

    &.active {
      filter: drop-shadow(0px 0px 5px #999);
      pointer-events: all;
      opacity: 1;
    }
  }

  svg {
    height: 100%;
    width: 100px;
    fill: white;
  }
}
