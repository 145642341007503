@font-face {
    font-family: "Founders-Grotesk-Regular";
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    src: url("https://assets.initialized.com/FoundersGroteskWeb-Regular.woff")
      format("woff");
    src: url("https://assets.initialized.com/FoundersGroteskWeb-Regular.woff2")
      format("woff2");
  }
  
  @font-face {
    font-family: "Founders-Grotesk-Bold";
    font-style: normal;
    font-weight: 700;
    src: url("https://assets.initialized.com/FoundersGroteskWeb-bold.woff")
      format("woff");
    src: url("https://assets.initialized.com/FoundersGroteskWeb-Semibold.woff2")
      format("woff2");
  }
  
  @font-face {
    font-family: "pitch";
    src: url("https://assets.initialized.com/Pitch-Regular.otf") format("otf");
    src: url("https://assets.initialized.com/pitch-regular.woff2") format("woff2");
    font-weight: 400;
  }
  
  @font-face {
    font-family: "Söhne";
    src: url("https://assets.initialized.com/So%CC%88hne-Leicht.otf")
      format("otf");
    src: url("https://assets.initialized.com/soehne-leicht.woff2") format("woff2");
    font-weight: 300;
  }
  @font-face {
    font-family: "Söhne-leicht";
    src: url("https://assets.initialized.com/So%CC%88hne-Leicht.otf")
      format("otf");
    src: url("https://assets.initialized.com/soehne-leicht.woff2") format("woff2");
    font-weight: 300;
  }
  @font-face {
    font-family: "Söhne";
    src: url("https://assets.initialized.com/So%CC%88hne-Buch.otf") format("otf");
    src: url("https://assets.initialized.com/soehne-buch.woff2") format("woff2");
    font-weight: 400;
  }
  
  @font-face {
    font-family: "Söhne Mono";
    src: url("https://assets.initialized.com/So%CC%88hneMono-Buch.otf")
      format("otf");
    src: url("https://assets.initialized.com/soehne-mono-buch.woff2")
      format("woff2");
    font-weight: 400;
    text-transform: uppercase;
  }